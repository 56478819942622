import instance from "../Services/Index";
import Swal from "sweetalert2";
import moment from "moment/moment";
import CryptoJS from "crypto-js";
import * as XLSX from "xlsx";
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Const List
export const genderList = [{ name: "Male" }, { name: "Female" }, { name: "Other" }];
export const roles = [{ roleName: "Driver", roleId: 2 }, { roleName: "User", roleId: 3 }]

export const location = [{ name: "Hyderabhad" }, { name: "karimnagar" }, { name: "Khammam" }, { name: "Nelluru" }, { name: "Vijayawada" }];

export const statusList = (status) => {
    switch (status) {
        case "Requested": return ["Requested", "Confirmed"]
        case "Confirmed": return ["Confirmed", "Completed"]
        case "Completed": return ["Completed"]
        default: return [status]

    }
}


//Const
export const css = {
    white: "#fff",
    black: "#000",
    themeColor: "#0089C3",
    textColor: "#A2A2A2",
    background: "#F7F8FD",
    iconSize2: "1.2rem",
    sassTheam: "#69908E",
    theamBorderRadious: "38px",
    dashboardTheam: "#e9f2f9",
    dashboardRadious: "15px",
    Completed: "#37AC00",
    Requested: "#a19898",
    Confirmed: "#5cc4ef"

};

export const baseURL = process.env.REACT_APP_API_URL;
export const basefileURL = process.env.REACT_APP_API_ASSETS_URL;

export const encryptKey = "1M3D^ERZ@B@!TN@1"
export const ivKey = "1B@!TN@@M3D^ERZ1"

//Please dont change these urls structure and names we used these in lot of places dynamically
export const urls = {
    Auth: {
        login: "Authentication/Login",
        forgotPassword: "Authentication/SendForgotPasswordMail",
        updatePassword: "Authentication/UpdatePassword",
        setPassword: "Authentication/SetPassword"
    },
    User: {
        save: "User/Save",
        getAll: "User/GetAll",
        getById: "User/GetById",
        upload: "User/UploadImage"
    },
    Ambulance: {
        save: "Ambulance/Save",
        getAll: "Ambulance/GetAll",
        getById: "Ambulance/GetById",
        upload: "Ambulance/UploadImage",
        status: "Ambulance/UpdateStatus",
        assignAmbulance: "Ambulance/AssignAmbulance",
    },
    Appointment: {
        save: "Appointment/Save",
        getAll: "Appointment/GetAll",
        getById: "Appointment/GetById",
        assignDriver: "Appointment/AssignDriver",
        status: "Appointment/UpdateStatus"
    }


}

//vALIDATION sCHEMA


export const addAmbulance = {
    vehicleName: { required: { value: true, message: "Please enter name" } },

    registrationNo: { required: { value: true, message: "Please enter mobile" } },

}

export const login = {
    email: {
        required: { value: true, message: "Please enter mobile" },
        pattern: {
            value: /^(?!.*[.]{2})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: "Email Formate is required"
        }
    },
    password: { required: { value: true, message: "Please enter password" } },

}
export const forgetPassword = {
    password: { required: { value: true, message: "Please enter password" } },
    confirmPassword: { required: { value: true, message: "Please enter password" } },
}

export const updateProfile = {
    firstName: { required: { value: true, message: "Please enter " }, },
    lastName: { required: { value: true, message: "Please enter " }, },
}

export const userCreate = {
    firstName: { required: { value: true, message: "Please enter " }, },
    lastName: { required: { value: true, message: "Please enter " }, },
    roleId: { required: { value: true, message: "Please enter " }, },
    phone: {
        required: { value: true, message: "Please enter " },
        minlength: { value: 16, message: "Please enter minimum" }
    },
    email: {
        required: { value: true, message: "Please enter ", },
        pattern: {
            value: /^(?!.*[.]{2})[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: "Email Formate is required"
        }
    }
}
export const assignDriver = {
    driverId: { required: { value: true, message: "Please enter " }, },
}

export const userUpdate = {
    firstName: { required: { value: true, message: "Please enter " }, },
    lastName: { required: { value: true, message: "Please enter " }, },
}

export const updatePassword = {
    password: {
        required: { value: true, message: "Please enter " },
        minlength: {
            value: 6,
            message: "Please enter 3 characters"
        }
    },
    newPassword: {
        required: { value: true, message: "Please enter " },
        minlength: {
            value: 6,
            message: "Please enter 3 characters"
        }
    },
    confirmPassword: {
        required: { value: true, message: "Please enter " },
        minlength: {
            value: 6,
            message: "Please enter 3 characters"
        }
    },

}

export const assignAmbulance = {
    ambulanceId: { required: { value: true, message: "Please enter " }, },
}

export const addAppointment = {
    numberOfAmbulances: { required: { value: true, message: "Please enter " }, minCount: { value: 1, message: "Enter minimum value 0" } },
    location: { required: { value: true, message: "Please enter " }, },
    bookingDate: { required: { value: true, message: "Please enter " }, },
    startTime: { required: { value: true, message: "Please enter " }, },
    endTime: { required: { value: true, message: "Please enter " }, },
}


//Shared functions used for Api Calls

// export const notify = (status, msg, showConfirmButton = false) => {
//     // Swal.fire({
//     //     position: "center",
//     //     icon: status == true ? "success" : "error",
//     //     title: msg,
//     //     showConfirmButton: showConfirmButton,
//     //     timer: 1500,
//     // });

// };

export const notify = (status, msg) => {
    const toastOptions = {
        position: 'bottom-left', // Set the position to bottom-right
        autoClose: 3000, // Close the toast after 3 seconds (adjust as needed)
        hideProgressBar: false, // Show the progress bar
        closeOnClick: true, // Close the toast when clicked
        pauseOnHover: true, // Pause the timer when hovering
        draggable: true, // Make the toast draggable
        progress: undefined, // Use the default progress bar
        // transition: Flip,
        theme: "dark",
        transition: Zoom,
        style: {
            width: '300px', // Adjust width as needed
        },
    };
    if (status == true) {
        toast.success(msg, toastOptions);
        // return (<ToastContainer />)
    } else {
        toast.error(msg, toastOptions)
        // return (<ToastContainer />)
    }
}

export const dilogueBox = (text, onClickFn, html) => {
    Swal.fire({
        title: text,
        html: html,
        showCancelButton: true,
        confirmButtonText: 'Yes',
    }).then((result) => {
        if (result.isConfirmed) {
            onClickFn()
        }
    })
}

//Type is nothing but 0 for save , 1 for getById 2 for getList
//showMsg is for if we need any status mgs need to show in alert
//showConfirmButton is for after saving confirmation will show
//callApi(url,payload,0)
// export const callApi = async (url, payload = {}, type = 2, showMsg = false, showConfirmButton = false) => {
//     try {
//         let res = await instance.post(url, payload);
//         type == 0 || showMsg && notify(res?.data?.status, res?.data?.message, showConfirmButton);
//         if (res?.data?.status == true) return type > 1 ? (Array.isArray(res?.data?.data) && res?.data?.data?.length > 0 ? res?.data?.data : []) :
//             type == 1 ? (Object.keys(res?.data?.data)?.length > 0 ? res?.data?.data : {}) :
//                 Object.keys(res?.data?.data)?.length > 0 ? res : {}
//         else return type > 1 ? [] : {}
//     } catch (e) {
//         return type > 1 ? [] : {}
//     }
// }

export const getList = async (url, data) => {
    try {
        let res = await instance.post(url, data);
        // let r = JSON.parse(res?.data)
        // console.log(res,"r")
        // return r?.status == true && Array.isArray(r?.data) && r?.data?.length > 0 ? r?.data : [];
         return res?. data?.status == true && Array.isArray(res?.data?.data) && res?.data?.data?.length > 0 ? res.data.data : [];
    } catch (e) {
        return [];
    }
};

export const getById = async (url, data) => {
    try {
        let res = await instance.post(url, data);
        return res?.data?.status == true && Object.keys(res?.data?.data)?.length > 0 ? res?.data?.data : {};
    } catch (e) {
        return {};
    }
};

export const save = async (url, data) => {
    try {
        let res = await instance.post(url, data);
        notify(res?.data?.status, res?.data?.message);
        return res?.data?.status == true ? res : {}
    } catch (e) {
        return {};
    }
};
export const saveWithoutMessage = async (url, data) => {
    try {
        let res = await instance.post(url, data);
        return res?.data?.status == true ? res : {}
    } catch (e) {
        return {};
    }
};

// export const saveWithConfirmation = async (url, data) => {
//     try {
//         let res = await instance.post(url, data);
//         notify1(res?.data?.status, res?.data?.message);
//         return res?.data?.status == true ? res : {}
//     } catch (e) {
//         return {};
//     }
// };



// export const saveWithoutMessageWithoutLoad = async (url, data) => {
//     let headers = {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + sessionStorage.getItem("token"),
//     };
//     try {
//         let res = await axios?.post(`${process.env.REACT_APP_API_URL}${url}`, data, { headers });
//         if (res?.data?.status == true) return res
//     } catch (e) {
//         return {};
//     }
//     return {};
// };

//Small functions used for all common

export const logOutFunction = () => (sessionStorage.clear(), window.location.pathname = "/")
export const getUserfromSS = () => JSON.parse?.(dec?.(sessionStorage.getItem("user")) || "{}")
export const getUniqueByKey = (array, key) => [...new Map(array.map((item) => [key ? item[key] : item, item])).values()] || []
export const settingdata = (data, keys) => keys.reduce((temp, key) => Object.assign(temp, { [key]: data[key] || "" }), {});
export const resettingdata = (keys) => keys.reduce((temp, key) => Object.assign(temp, { [key]: "" }), {});
export const sortingList = (array, key, type) => array.sort((a, b) => type == "Number" ? a?.[key] - (b?.[key]) : type == "Date" ? new Date(a?.[key]) - new Date(b?.[key]) : a?.[key]?.localeCompare(b?.[key]))
export const stringToInt = (data, keys) => keys.reduce((temp, key) => Object.assign(temp, { [key]: returningValue(data[key]) == '' ? 0 : parseInt(data[key], 10) }), data);
export const stringToArray = (str, dataArray, idKey) => str?.length > 0 && str?.split(',')?.map((e) => ({ ...dataArray?.find((v) => v?.[idKey] == e) })) || []

export const removeDuplicates = (array, key) => {
    const uniqueKeys = new Set();
    return array.filter(e => !uniqueKeys.has(e?.[key]) ? (uniqueKeys.add(e?.[key]), true) : false);
};

export const enc = (data) => {
    const key = CryptoJS.enc.Utf8.parse(encryptKey);
    const iv = CryptoJS.enc.Utf8.parse(ivKey);

    if (returningValue(data, "") != '') {
        const encrypteddata = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key,
            {
                keySize: 128 / 8,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            })?.toString()
        const encrypted = encrypteddata.toString()
        return encrypted
    } else {
        return null
    }
}

export const dec = (data) => {
    const key = CryptoJS.enc.Utf8.parse(encryptKey);
    const iv = CryptoJS.enc.Utf8.parse(ivKey);

    if (returningValue(data, "") != '') {
        const decryptedBytes = CryptoJS.AES.decrypt(data, key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        const decrypted = decryptedBytes.toString(CryptoJS.enc.Utf8);
        return decrypted || ""
    }
}

export const returningValue = (v, type) => {
    const isValid = v !== undefined && v !== null && v !== "" && v !== NaN && v !== "null";
    if (isValid) {
        switch (type) {
            case "Alphabet":
                var va = v?.replace(/[^a-zA-Z ]/g, "")?.replace(/^\s+/, "")?.replace(/\s+/g, " ");
                va = va?.charAt(0).toUpperCase() + v.slice(1);
                return va;
            case "AlphaNumaric":
                var va = v?.replace(/[^a-zA-Z0-9 ]/g, "")?.replace(/^\s+/, "")?.replace(/\s+/g, " ");
                va = va?.charAt(0)?.toUpperCase() + v?.slice(1)
                return va
            case "Phone":
                let n = v?.replace("+966", "")?.replace(/[^0-9]/g, "")?.replace(/\s+/g, '');
                let l = n?.length;
                if (l > 0 && l <= 2) return n?.replace(/(\d{1})/, "+966 $1", "");
                else if (l > 2 && l <= 5) return n?.replace(/(\d{2})(\d{1})/, "+966 $1 $2", "");
                else if (l > 5 && l <= 9) return n?.replace(/(\d{2})(\d{3})(\d{1})/, "+966 $1 $2 $3", "");
                break;
            case "Date": return new Date(v) !== "Invalid Date" ? new Date(v) : "";
            case "Email": return v?.toLowerCase()?.replace(/\s+/g, '');
            case "Number": return typeof (v) == "string" ? v?.replace(/[^0-9 ]/g, "") : v
            case "Decimal": return parseFloat(v).toFixed(2);
            case "DateTime": return v === "Invalid date" ? "-" : v;
            case "LocalTime": return moment.utc(v, "YYYY-MM-DDTHH:mm:ss").local().format('YYYY-MMM-DD H:mm:ss');
            case "NumberString": return v?.replace(/[^0-9]/g, "");
            case "WithoutSpaces": return v?.toString();
            case "ErrorColor": return "border border-danger";
            case "RemoveSpaces": return v?.replace(/\s+/g, '')
            default: return v;
        }
    } else {
        switch (type) {
            case "Array": return [];
            case "Bool": return false
            default: return "";
        }
    }
};

export const navigationFn = (user) => {
    // console.log(user, "user")
    const roleId = user?.roleId;
    const roleRoutes = {
        1: "/v1/service/appointments/all-appointments",/*admin*/
        2: "/v1/service/hospital/all-hospitals",
        3: "/v1/service/appointments/all-appointments",/*user*/
        4: "/v1/service/physiciandashboard",
        5: "/v1/service/appointment/all-appointments",
    };
    return roleRoutes[roleId] || "";
};


export const exportExcel = (data, name) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(data);
    const columnWidths = data.reduce((acc, row) => {
        row.forEach((cell, i) => {
            acc[i] = Math.max(acc[i] || 0, String(cell).length + 2);
        });
        return acc;
    }, []);
    ws["!cols"] = columnWidths.map((width) => ({ width }));
    XLSX.utils.book_append_sheet(wb, ws, name);
    XLSX.writeFile(wb, `${name}.xlsx`);
}

export const mobileDetect = () => {
    const agent = window.navigator.userAgent;
    const deviceWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const IsIPhone = agent.includes('iPhone');
    const IsIPad = agent.includes('iPad') || (IsIPhone && deviceWidth > 750);
    if (IsIPad) {
        IsIPhone = false;
    }
    const macApp = agent.includes('Macintosh');
    if (macApp) {
        const canvas = document.createElement('canvas');
        if (canvas) {
            const context = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
            if (context) {
                const info = context.getExtension('WEBGL_debug_renderer_info');
                if (info) {
                    const renderer = context.getParameter(info.UNMASKED_RENDERER_WEBGL);
                    if (renderer.includes('Apple')) {
                        IsIPad = true;
                    }
                }
            }
        }
    }

    const IsIOSApp = IsIPad || IsIPhone;
    const IsAndroid = agent.includes('Android');
    const IsAndroidPhone = IsAndroid && deviceWidth <= 960;
    const IsAndroidTablet = IsAndroid && !IsAndroidPhone;

    let message = '';
    if (IsIPhone) {
        message = 'Device is iPhone';
    } else if (IsIPad) {
        message = 'Device is iPad';
    } else if (IsAndroidTablet || IsAndroidPhone || IsAndroid) {
        message = 'Device is Android';
    } else {
        message = 'Device is Mac || Windows Desktop';
    }

    return {
        message,
        isMobile: IsIOSApp || IsAndroid || IsAndroidTablet || IsAndroidPhone,
    };
};