// import React, { useEffect, useState } from "react";
// import Plot from "react-plotly.js";
// import { getList, returningValue } from "../Utils/Config";
// import moment from "moment";
// import { useFormValidation } from "../Validations/useFormValidations";
// import { hover } from "@testing-library/user-event/dist/hover";

// const MyPlot = () => {
//   const [values, setValues] = useState([]);
//   const [filValues, setFilValues] = useState([]);
//   const [types, setTypes] = useState([]);
//   const [dates, setDates] = useState([]);
//   const { data, formChange } = useFormValidation({});

//   const getAllData = async () => {
//     let res = await getList("GetData", { data: "data" });
//     setValues(res);
//     setFilValues(res);
//   };

//   const settingTraces = () => {
//     let res = values?.map((e) => ({ ...e, ...e?.indicators }));
//     if (returningValue(data?.fromDate, "")) res = res?.filter((e) => moment(e?.["Encounter date"])?.format("YYYYMMDD") >= moment(data?.fromDate)?.format("YYYYMMDD"));
//     if (returningValue(data?.toDate, "")) res = res?.filter((e) => moment(e?.["Encounter date"])?.format("YYYYMMDD") <= moment(data?.toDate)?.format("YYYYMMDD"));
//     res = res?.map((e) => ({ ...e, date: moment(e?.["Encounter date"])?.format("YYYY-MM-DD") }));
//     setTypes(res?.map((e) => e?.["Encounter Type"]));
//     setDates(res?.map((e) => e?.date));
//     setFilValues(res);
//   };

//   useEffect(() => {
//     settingTraces();
//   }, [filValues, data]);

//   useEffect(() => {
//     getAllData();
//   }, []);

//   const trace = {
//     x: dates,
//     y: types,
//     mode: "markers",
//     marker: {
//       color: 'red',
//       size: 10
//     },
//     hoverinfo: 'text',
//     text: filValues.map(e => `Type: ${e["Encounter Type"]} Date:${e.date} cost:${e?.["Cost"]}`)
//   };

//   const layout = {
//     title: "ANATRX",
//   };

//   return (
//     <div>
//       From date:
//       <input type="date" name="fromDate" value={returningValue(data?.fromDate)} onChange={formChange("")} />
//       To date:
//       <input type="date" name="toDate" value={returningValue(data?.toDate)} onChange={formChange("")} />
//       <Plot style={{ width: "100%", height: "100%" }} data={[trace]} layout={layout} />
//       <div>Total Cost : {filValues?.reduce((a, c) => a + c?.["Cost"], 0)} </div>
//     </div>
//   );
// };

// export default MyPlot;

import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { getList, getUniqueByKey, returningValue } from "../Utils/Config";
import moment from "moment";
import { useFormValidation } from "../Validations/useFormValidations";
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import anatrx from "../Images/red_logo.png";
import { useNavigate } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiSolidAmbulance } from "react-icons/bi";
import { Title } from "chart.js";

const Home = () => {
  const [values, setValues] = useState([]);
  const [filValues, setFilValues] = useState([]);
  const [types, setTypes] = useState([]);
  const [dates, setDates] = useState([]);
  const { data, formChange, addObject, handleDateChange } = useFormValidation({});
  const navigate = useNavigate();

  const getAllData = async () => {
    let res = await getList("GetData", { data: "data" });
    res = res?.map((e) => ({ ...e, ...e?.indicators }));
    setValues(res);
    setFilValues(res);
  };

  const settingTraces = () => {
    let res = values;
    if (returningValue(parseInt(data?.idNumber), "")) res = res?.filter((e) => e["ID Number"] === parseInt(data?.idNumber));
    if (returningValue(data?.fromDate, "")) res = res?.filter((e) => moment(e?.["Encounter date"])?.format("YYYYMMDD") >= moment(data?.fromDate)?.format("YYYYMMDD"));
    if (returningValue(data?.toDate, "")) res = res?.filter((e) => moment(e?.["Encounter date"])?.format("YYYYMMDD") <= moment(data?.toDate)?.format("YYYYMMDD"));
    if (returningValue(data?.diagnosis, "")) res = res?.filter((e) => e?.["Diagnosis.x"] === data?.diagnosis);
    if (returningValue(data?.visitType, "")) res = res?.filter((e) => e?.["Encounter Type"] == data?.visitType);

    res = res?.map((e) => ({ ...e, date: moment(e?.["Encounter date"])?.format("YYYY-MM-DD") }));
    setTypes(res?.map((e) => e?.["Encounter Type"]));
    setDates(res?.map((e) => e?.date));
    setFilValues(res);
  };

  useEffect(() => {
    settingTraces();
  }, [filValues, data]);

  useEffect(() => {
    getAllData();
  }, []);

  const uniqueTypes = [...new Set(types)];

  const colors = ["orange", "purple", "IndianRed", "magenta"];

  const typeColorMap = {};
  uniqueTypes.forEach((type, index) => {
    typeColorMap[type] = colors[index % colors.length];
  });

  const traces = uniqueTypes.map((type, index) => {
    let symbol;

    switch (index % 5) {
      case 1:
        symbol = "circle";
        break;
      case 2:
        symbol = "circle";
        break;
      case 3:
        symbol = "circle";
        break;
      case 4:
        symbol = "circle";
        break;
      default:
        symbol = "circle";
        break;
    }

    const filteredDates = dates.filter((date, i) => types[i] === type);
    const filteredTypes = types.filter((t) => t === type);

    return {
      x: filteredDates,
      y: filteredTypes,
      mode: "markers",
      marker: {
        symbol: symbol,
        color: typeColorMap[type],
        size: 10,
      },
      hoverinfo: "text",
      text: filValues.filter((e) => e["Encounter Type"] === type).map((e) => `</br> Type: ${e["Encounter Type"]} </br> Diagnosis:${e?.["Diagnosis.x"]} </br> Date:${moment(e.date)?.format("DD-MM-YYYY")} </br> Cost:${e?.["Cost"]}`),
      name: type,
    };
  });

  const layout = {
    legend: {
      traceorder: "normal",
    },
  };
  const buttonColors = uniqueTypes.map((type) => ({
    type,
    color: typeColorMap[type] || "gray", // Default to gray if color is not found
  }));

  const getFiltersValues = (key) => {
    let res = values?.filter((v) => (data?.["idNumber"] > 0 ? data?.["idNumber"] == v?.["ID Number"] : true));
    if (key == "Diagnosis.x") res = res?.filter((v) => (returningValue(data?.["visitType"], "") ? v?.["Encounter Type"] == data?.["visitType"] : true));
    return getUniqueByKey(res, key);
  };

  useEffect(() => {
    addObject({ visitType: "", diagnosis: "" });
  }, [data?.idNumber]);

  return (
    <div className="">
      <Col lg={12} className="border  d-flex justify-content-between align-items-center " style={{ backgroundColor: "#000" }}>
        <Col lg={6} className="text-start ">
          <img src={anatrx} width={100} height="auto" className="ms-5" />
        </Col>

        <Col lg={6} className="text-end">
          <h3 className="text-white me-5">Anatrx Analytics</h3>
        </Col>
      </Col>
      <Col lg={12} className=" border p-3 d-flex justify-content-between  ">
        <Col lg={6} className="d-flex justify-content-center">
          <div className="col-md-4 text-start p-1">
            <select name="idNumber" onChange={formChange("")} value={returningValue(data?.["idNumber"], "")} className="form-control ">
              <option value={""}>Select ID</option>
              {getUniqueByKey(values, "ID Number")?.map((e, i) => (
                <option key={i}>{e?.["ID Number"]}</option>
              ))}
            </select>
          </div>
          <div className="col-md-4 text-start p-1">
            <select name="visitType" onChange={formChange("")} value={returningValue(data?.["visitType"], "")} className="form-control ">
              <option value={""}>Select Visit</option>
              {getFiltersValues("Encounter Type")?.map((e, i) => (
                <option key={i}>{e?.["Encounter Type"]}</option>
              ))}
            </select>
          </div>
          <div className="col-md-4 text-start p-1">
            <select name="diagnosis" onChange={formChange("")} value={returningValue(data?.["diagnosis"], "")} className="form-control ">
              <option value={""}>Select Diagnosis</option>
              {getFiltersValues("Diagnosis.x")?.map((e, i) => (
                <option key={i}>{e?.["Diagnosis.x"]}</option>
              ))}
            </select>
          </div>
        </Col>
        <Col lg={6} className="d-flex  justif-content-end ">
          <div className="col-md-4 p-1"></div>
          <div className="col-md-4 p-1    ">
            <ReactDatePicker
              className={`form-control`}
              selected={returningValue(data?.["fromDate"], "Date")}
              minDate={new Date(1900, 1, 1)}
              onChange={(e) => {
                handleDateChange(e, "fromDate");
              }}
              autoComplete="off"
              name="fromDate"
              dateFormat="dd-MM-yyyy"
              placeholderText={"From Date"}
              popperClassName="react-datepicker-popper"
              showMonthDropdown
              showYearDropdown
              style={{ fontSize: "20px" }}
              dropdownMode="select"
            />
          </div>
          <div className="col-md-4 p-1     ">
            <ReactDatePicker
              className={`form-control `}
              selected={returningValue(data?.["toDate"], "Date")}
              minDate={new Date(1900, 1, 1)}
              onChange={(e) => {
                handleDateChange(e, "toDate");
              }}
              autoComplete="off"
              name="toDate"
              dateFormat="dd-MM-yyyy"
              placeholderText={"To Date"}
              popperClassName="react-datepicker-popper"
              showMonthDropdown
              showYearDropdown
              style={{ fontSize: "20px" }}
              dropdownMode="select"
            />
          </div>
        </Col>
      </Col>
      <Col lg={12}>
        <Plot style={{ width: "100%", height: "100%" }} className="" data={traces} layout={layout} />
        {buttonColors.map(({ type, color }) => (
          <Button key={type} className="ms-2 border-0" style={{ backgroundColor: color }}>
            {type} Cost :{" "}
            {filValues
              ?.filter((e) => e?.["Encounter Type"] === type)
              ?.reduce((a, c) => a + c?.["Cost"], 0)
              .toFixed(2)}
          </Button>
        ))}

        <Button className="ms-2" style={{ backgroundColor: "#8590ad" }}>
          Total Cost : {filValues?.reduce((a, c) => a + c?.["Cost"], 0).toFixed(2)}
        </Button>
      </Col>
    </div>
  );
};

export default Home;
